<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t('materials.traceability.edit.title')}}</h3>
        <h3 v-else>{{ $t('materials.traceability.add.title')}}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>

            <!-- Name -->
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                outlined
                :label="$t('materials.traceability.labels.name')"
              />
            </v-col>

            <!-- position -->
            <v-col cols="12">
              <v-text-field
                v-model="form.position"
                outlined
                type="number"
                :label="$t('materials.traceability.labels.position')"
              />
            </v-col>

            <!-- Icon -->
            <v-col cols="12">
              <v-select
                v-model="form.icon"
                item-value="value"
                item-text="value"
                :items="$t('constants.traceabilityIcons.list')"
                :label="$t('materials.traceability.labels.icon')"
                outlined
              />
            </v-col>

            <!-- transportationType -->
            <v-col cols="12">
              <v-select
                v-model="form.transportationType"
                item-value="value"
                item-text="value"
                :items="$t('constants.traceabilityTransportationTypes.list')"
                :label="$t('materials.traceability.labels.transportationType')"
                outlined
              />
            </v-col>

            <!-- Supplier -->
            <v-col cols="12">
              <SelectSupplier
                :value="form.supplier"
                @input="onInputSupplier"
              />
            </v-col>

            <!-- City -->
            <v-col cols="12">
              <v-text-field
                outlined
                clearable
                v-model="form.address.city"
                :label="$t('common.address.city')"
              />
            </v-col>

            <!-- Country -->
            <v-col cols="12">
              <SelectCountries
                :enableClearable="true"
                :value="form.address.country"
                @input="onInputCountry"
              />
            </v-col>

            <!-- Region -->
            <v-col cols="12">
              <v-text-field
                clearable
                outlined
                :enableClearable="true"
                v-model="form.address.region"
                :label="$t('common.address.region')"
              />
            </v-col>

            <!-- Km to next -->
            <v-col cols="12">
              <v-text-field
                type="number"
                :label="$t('materials.traceability.labels.kmToNext')"
                outlined
                v-model="form.kmToNext"
              />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules   from "@/mixins/formRules";

export default {
  name: "TraceabilityDialog",
  components: {
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
    SelectCountries: () => import("@/components/Common/Inputs/SelectCountries"),
    SelectSupplier: () => import("@/components/Common/Inputs/SelectSupplier")
  },

  props: {
    materialId: { type: String, default: null }
  },

  mixins: [dialogMixin, formRules],

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        name: "",
        position: 0,
        icon: "",
        transportationType: "",
        supplier: null,
        kmToNext: 0,
        address: {
          city: "",
          country: ""
        }
      }
    }
  },

  methods: {

    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects(["supplier"]);
        this.form.position = Number(this.form.position);
        this.form.kmToNext = Number(this.form.kmToNext);

        if (this.item)
          this.submitEdit();
        else
          this.submitAdd();
      }
    },

    submitAdd() {
      this.$http.post(`/materials/${this.materialId}/traceability-steps`, this.form, {
        headers: {Authorization: "Bearer " + this.$session.get('jwt')}
      })
        .then(res => {
          this.$store.commit("toast/showSuccess", this.$t('materials.traceability.add.success'));
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },


    submitEdit() {

      this.$http
        .put(`/materials/${this.materialId}/traceability-steps/${this.item.id}`, this.form, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.$store.commit("toast/showSuccess", this.$t('materials.traceability.edit.success'));
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputSupplier(val) {
      this.form.supplier = val;
    },
    onInputCountry(val) {
      this.form.address.country = val;
    }
  }
};
</script>

<style scoped>

</style>
